<template>
	<div  :style="'background: '+webset['homebakcolor']" class="home-wrapper">
		<search :logo="webset.logo" :webset="webset"  leimulist="topmenu" />
		<!-- banner -->
		<div class="banner w1200">
			<el-carousel trigger="click" height="400px">
				<el-carousel-item v-for="(item,index) in banner" :key="index">
					<img :src="item.image" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- product -->
		<div v-if="item1.code==nowleimu || !nowleimu" v-for="(item1,index1) in leimulist" :key="index1" class="product-list">
			<div class="main w1200">
				<div class="title-box flex-start">
					<span  @click="getGoodsListT(item1.code,'')" class="title">{{item1.title}}</span>
					<ul class="menu flex-start">
						<li v-if="leimugoodslist[item1.code]['leimu']" @click="getGoodsListT(item1.code,item.code)" :class="['items',menu.active==item.code?' title active':'title']"
						 v-for="(item,index) in leimugoodslist[item1.code]['leimu']" :key="index">{{item.title}}</li>
					</ul>
				</div>
				<div class="content flex-bt">
					<product  v-for="(item,index) in leimugoodslist[item1.code]['goods']" :key="index" :product="item" />
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import search from '@/components/Search'
import product from '@/components/Product'

export default {
    name: 'Home',
    components: {search, product},
	data() {
		return {
			html:'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
			topmenu:[],
			uniacid: parseInt(window.localStorage.getItem('uniacid')),
			//uniacid:19,
			moduleList: [],
			leimugoodslist: [],
			banner: [],
			webset:[],
			menu: {
				active: '',
				list: []
			},
			leimulist:[],
			size: 10,
			page: 1,
			pageCount: 0,
			goodsList: [],
			leimu:[],
			nowleimu:'',
		}
	},
	created() {	
			console.log('home...');
		
		 this.getuniacid()
		 	if(this.$route.query.code){
				console.log('leimu:',this.$route.query.code);
				   this.nowleimu= this.$route.query.code
				}
		
	        	 
	
		//this.getGoodsList()
	
	},
	methods: {
		// 获取首頁模块
		async getuniacid(){
			// this.uniacid= 4 ;
			 var ss = window.localStorage.getItem('webinfo');
			 if(!ss){
				 var url = window.location.href;
				 var dz_url = url.split("#")[0];
				 var www_dz_url = dz_url.split("/")[2];
				 			
				
				var centerdomain= www_dz_url.split(".");
				console.log('centerdomain:',centerdomain);
				let res
				if(centerdomain[1]=='khshop'){
					res = await this.$http.get('app.layout/weblist',{centerdomain:centerdomain[0]})
					console.log('websiteinfo:',res);
				}else{
					 res = await this.$http.get('app.layout/weblist',{siteroot:www_dz_url})
					console.log('websiteinfo:',res);
				}
				
				 
				 console.log('websiteinfo:',res);
				 ss = JSON.stringify(res[0])		
		
			 }
			 var ssarray = JSON.parse(ss)
			 console.log('ss',ss);
			 console.log('ssarray.ucid',ssarray['ucid']);
			 this.webset =  ssarray 
			 this.uniacid = ssarray['ucid'] 
			 //this.uniacid=20;
			//  window.localStorage.setItem('uniacid',this.uniacid)
		
			 document.title = ssarray.webtitle 
			// this.getwebset()
			 this.getleimugoods()
		},
		
		async getHomeLayout() {
			let res = await this.$http.get('/app.layout', {
				url: '/pages/tabbar/index/index',uniacid:this.uniacid
			})
			if (res.code !== 0) return
			this.moduleList = res.data.list
			this.banner = res.data.list.reduce((arr,item)=>{
				item.type == 'carousel' && (arr = item.data)
				return arr
			},[])
			console.log('banner',this.banner);
		},



		//获取分类
		async getleimu(){
			let res = await this.$http.get('/app.layout/mulu',{uniacid:this.uniacid})
			if(res){
				this.menu.list = res.itemtitle.map((item,index)=>{
					let obj = {label: item, id: res.itemcode[index]}
					return  obj
				})
			}
		},
		async gettopmenu(){
			let res = await this.$http.get('/user/topmenu',{uniacid:this.uniacid})
			if(res){
				 this.topmenu= res.data 
			 
			}
		},
		async getleimugoods(){
			let res = await this.$http.get('/app.layout/getmulugoods',{
				uniacid:this.uniacid
			})
			if(res){
				console.log('gsl',res);
				   this.leimugoodslist = res 
				   this.getHomeLayout()
				    
				    this.getleimu1()
			}
		},
		
		async getwebset(){
			let res = await this.$http.get('/app.layout/getwebset?id=4',{})
			if(res){
				console.log('uniacid',this.uniacid);
				console.log('webset',res);
				   this.webset = res 
				  
			}
		},
		
		async getleimu1() {
    try {
        console.log('mulu1');

        let res = await this.$http.get('/app.layout/mulu1', {
            uniacid: this.uniacid
        });

        if (res && Array.isArray(res)) {
            console.log(res);
            this.leimulist = res;

            console.log('ok');
            console.log(this.leimulist);

            let locale = window.localStorage.getItem('locale') || this.$i18n.locale;

            if (locale === 'zh-CN') {
                for (let i = 0; i < res.length; i++) {
                    const str = chineseLanguageLoader(res[i].title, { language: 'zh-CN' });
                    this.leimulist[i].title = str;
                }
            } else if (locale === 'EN') {
                if (this.uniacid == 17) {
                    this.leimulist[0].title = "Professional Toothbrush Series";
                    this.leimulist[1].title = "Long Handle Interdental Brush";
                } else if (this.uniacid == 20) {
                    this.leimulist[0].title = "Course";                            // 課程
                    this.leimulist[1].title = "Gloves";                            // 手套
                    this.leimulist[2].title = "Sword Uniform";                     // 劍服
                    this.leimulist[3].title = "Mask";                              // 面罩
                    this.leimulist[4].title = "Sword Blade / Sword Accessories";   // 劍條/劍配件
                    this.leimulist[5].title = "Sword Pants";                       // 劍褲
                    this.leimulist[6].title = "Sword Bag";                         // 劍袋
                } else if (this.uniacid == 24) {
                    this.leimulist[0].title = "Storage";
                } else if (this.uniacid == 33) {
                    this.leimulist[0].title = "Container Shipping";
                    this.leimulist[1].title = "Storage Services";
                    this.leimulist[2].title = "Air transportation";
                } else if (this.uniacid == 43) {
                    this.leimulist[0].title = "Service";
                }else if (this.uniacid == 29) {
                    this.leimulist[0].title = "London";
                }
				
            }
        } else {
            console.error('Unexpected response format or empty response.');
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
},
		async getleimu2(){
				if(uni.getStorageSync('category_one1')){
					this.category_one=uni.getStorageSync('category_one1')
					uni.setStorageSync("category_one1", '')
					console.log('one',this.category_one);
				}
				let res = await uni.$http.get('/app.layout/mulu2', {
					 father_code:this.category_one	 
				})
					  if(res.data){
						  this.leimutitle = res.data
					      console.log(this.leimutitle);
						  console.log('ok:leimu2');
					  }
				
		},

		//点击分类
		async onClickItem(e,f) {
			this.menu.active = e
			this.goodsList=[]
			this.page=0
			//this.getGoodsList()
		},

		//获取产品列表
		async getGoodsListT(one,two) {
			this.menu.active = two
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: 0,
				size: this.size,
				category_one: one,
				category_two: two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
		    this.leimugoodslist[one]['goods']=res.data.list
		 
		},
		//获取产品列表
		async getGoodsList(category_two) {
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: this.page++,
				size: this.size,
				category_one: this.menu.active,
				category_two: category_two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
			this.pageCount = Math.ceil(data.total / this.size)
			if (this.page <= 2) this.goodsList = []
			this.goodsList = this.goodsList.concat(data.list)
		},
	}
}
</script>
<style scoped lang="scss">
 .home-wrapper {
    .banner-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .banner {
      width: 80%;
      max-width: 960px; // 80% of 1200px
      height: 400px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
		.product-list{
			margin: 10px auto;
			margin-top: 50px;
			margin-left: 10px;
			.main{
				.title{
					font-size: 20px;
					font-weight: bold;
				}
				.content{
					box-sizing: border-box;
					padding: 30px 0;
					flex-wrap: wrap;
					//overflow: scroll;
					overflow-y: auto;
					/*max-height: 680px;*/
					// background: #fff;
				}
				.menu{
					margin-left: 20px;
					.items{
						margin-right: 10px;
						font-weight: 400;
						
						cursor: pointer;
						&.active{
							color: red;
							font-weight: bold;
							border-bottom: 2px solid #e93323;
						}
						@media (max-width: 1200px) {
    .home-wrapper {
      .banner {
        width: 80%;
      }
    }
  }

  @media (max-width: 768px) {
    .home-wrapper {
      .banner {
        width: 90%; // Slightly wider on smaller screens for better visibility
      }
    }
  }
					}
				}
			}
		}
	}
</style>
